import Layout1 from '@/layout/Layout1'

export default [{
  path: '/',
  component: Layout1,
  children: [
    {
      path: 'dashboard',
      component: () => import('@components/support/Dashboard')
    },
    {
      path: 'articles',
      component: () => import('@components/support/Articles')
    },
    {
      path: 'article/',
      component: () => import('@components/support/ArticleSingle')
    },
    {
      path: 'article/:id',
      component: () => import('@components/support/ArticleSingle')
    },
    {
      path: 'sites',
      component: () => import('@components/support/Sites')
    },
    {
      path: 'site',
      component: () => import('@components/support/SiteSingle')
    },
    {
      path: 'site/:id',
      component: () => import('@components/support/SiteSingle')
    },
    {
      path: 'tickets',
      component: () => import('@components/support/Tickets')
    },
    {
      path: 'ticket',
      component: () => import('@components/support/TicketsSingle')
    },
    {
      path: 'ticket/:id',
      component: () => import('@components/support/TicketsSingle')
    },
    {
      path: 'team',
      component: () => import('@components/support/Team')
    },
    {
      path: 'profile',
      component: () => import('@components/admin/Profile')
    }
  ]
}]
