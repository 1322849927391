import Vue from 'vue'
import Router from 'vue-router'
import Meta from 'vue-meta'
import NotFound from '@components/NotFound'

import globals from '@/globals'

import auth from './auth'
import suport from './suport'

Vue.use(Router)
Vue.use(Meta)

const ROUTES = [
  // Default route
  { path: '', redirect: '/dashboard' }
]
  .concat(auth)
  .concat(suport)

// 404 Not Found page
ROUTES.push({
  path: '*',
  component: NotFound
})

let routerMode = process.env.NODE_ENV !== 'production' ? 'hash' : 'history'

if (process.env.IS_ELECTRON) routerMode = 'hash'
if (process.env.CORDOVA_PLATFORM) routerMode = 'hash'

const router = new Router({
  base: '/',
  mode: routerMode,
  routes: ROUTES
})

router.afterEach(() => {
  // On small screens collapse sidenav
  if (window.layoutHelpers && window.layoutHelpers.isSmallScreen() && !window.layoutHelpers.isCollapsed()) {
    setTimeout(() => window.layoutHelpers.setCollapsed(true, true), 10)
  }

  // Scroll to top of the page
  globals().scrollTop(0, 0)
})

router.beforeEach((to, from, next) => {
  // Set loading state
  document.body.classList.add('app-loading')

  const token = localStorage.getItem('access_token')
  if (!token && to.path !== '/auth/login' && to.path !== '/auth/register' && to.path !== '/auth/password/reset') {
    next('/auth/login')
  }
  // Add tiny timeout to finish page transition
  setTimeout(() => next(), 10)
})

export default router
