<template>
  <sidenav :orientation="orientation" :class="curClasses">

    <!-- Inner -->
    <div class="sidenav-inner" :class="{ 'py-1': orientation !== 'horizontal' }">

      <div class="user-info text-center m-3">
        <img :src="`${publicUrl}img/placeholder2.png`" alt class="ui-w-30 rounded-circle">
        <span class="px-1 ml-lg-0 text-center">{{user.firstname}} {{user.lastname}}</span>
      </div>
      <sidenav-router-link icon="ion ion-md-speedometer" to="/dashboard" :exact="true">Dashboard</sidenav-router-link>
      <!-- <sidenav-router-link icon="ion ion-md-notifications" to="/alerts" :exact="true">Alerte</sidenav-router-link> -->
      <sidenav-router-link v-if="user.role=='admin'" icon="ion ion-md-bookmarks" to="/sites" :exact="true">Siteuri</sidenav-router-link>
      <sidenav-router-link icon="ion ion-md-list" to="/articles" :exact="true">Articole</sidenav-router-link>
      <sidenav-router-link icon="ion ion-md-list-box" to="/tickets" :exact="true">Tichete</sidenav-router-link>
      <sidenav-router-link icon="ion ion-md-people" to="/team" :exact="true">Echipa</sidenav-router-link>
      <sidenav-router-link icon="ion ion-md-person" to="/profile" :exact="true">Profil</sidenav-router-link>
      <sidenav-router-link v-if="user.role=='admin'" icon="ion ion-md-people" to="/team" :exact="true">Echipa</sidenav-router-link>
      <div @click="logout()" class="sidenav-item logout-button" >
        <a href="javascript:void(0)" target="_self" class="sidenav-link">
          <i class="sidenav-icon ion ion-ios-log-out"></i>
          <div>Logout</div>
        </a>
      </div>
    </div>
  </sidenav>
</template>
<style scoped>
.user-info {
  display: none;
}
@media (max-width: 825px) {
   .user-info {
     display: block;
   }
}
</style>
<script>
import { Sidenav, SidenavLink, SidenavRouterLink, SidenavMenu, SidenavHeader, SidenavBlock, SidenavDivider } from '@vendor/libs/sidenav'

export default {
  name: 'app-layout-sidenav',
  components: {
    /* eslint-disable vue/no-unused-components */
    Sidenav,
    SidenavLink,
    SidenavRouterLink,
    SidenavMenu,
    SidenavHeader,
    SidenavBlock,
    SidenavDivider
    /* eslint-enable vue/no-unused-components */
  },

  props: {
    orientation: {
      type: String,
      default: 'vertical'
    }
  },

  data: () => ({
    role: 'user',
    user: {}
  }),
  created () {
    this.$http.get('/users/me').then(response => {
      this.user = response.data
    }).catch(e => {
      console.log(e)
    })
  },
  computed: {
    curClasses () {
      let bg = this.layoutSidenavBg

      if (this.orientation === 'horizontal' && (bg.indexOf(' sidenav-dark') !== -1 || bg.indexOf(' sidenav-light') !== -1)) {
        bg = bg
          .replace(' sidenav-dark', '')
          .replace(' sidenav-light', '')
          .replace('-darker', '')
          .replace('-dark', '')
      }

      return `bg-${bg} ` + (
        this.orientation !== 'horizontal'
          ? 'layout-sidenav'
          : 'layout-sidenav-horizontal container-p-x flex-grow-0'
      )
    }
  },

  methods: {
    isMenuActive (url) {
      return this.$route.path.indexOf(url) === 0
    },

    isMenuOpen (url) {
      return this.$route.path.indexOf(url) === 0 && this.orientation !== 'horizontal'
    },

    toggleSidenav () {
      this.layoutHelpers.toggleCollapsed()
    },

    logout () {
      this.$http.post('/auth/logout', {}).then(response => {
        localStorage.removeItem('access_token')
        this.$router.push('/auth/login')
      }).catch(e => {
        console.log(e)
      })
    }
  }
}
</script>
